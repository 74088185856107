// require('./bootstrap');


var vid = document.getElementById("videobg");
vid.playbackRate = 0.55;


// ——————————————————————————————————————————————————
// TextScramble
// ——————————————————————————————————————————————————

class TextScramble {
    constructor(el) {
      this.el = el
      this.chars = '!<>-_\\/[]{}—=+*^?#________'
      this.update = this.update.bind(this)
    }
    setText(newText) {
      const oldText = this.el.innerText
      const length = Math.max(oldText.length, newText.length)
      const promise = new Promise((resolve) => this.resolve = resolve)
      this.queue = []
      for (let i = 0; i < length; i++) {
        const from = oldText[i] || ''
        const to = newText[i] || ''
        const start = Math.floor(Math.random() * 40)
        const end = start + Math.floor(Math.random() * 40)
        this.queue.push({ from, to, start, end })
      }
      cancelAnimationFrame(this.frameRequest)
      this.frame = 0
      this.update()
      return promise
    }
    update() {
      let output = ''
      let complete = 0
      for (let i = 0, n = this.queue.length; i < n; i++) {
        let { from, to, start, end, char } = this.queue[i]
        if (this.frame >= end) {
          complete++
          output += to
        } else if (this.frame >= start) {
          if (!char || Math.random() < 0.28) {
            char = this.randomChar()
            this.queue[i].char = char
          }
          output += `<span class="dud">${char}</span>`
        } else {
          output += from
        }
      }
      this.el.innerHTML = output
      if (complete === this.queue.length) {
        this.resolve()
      } else {
        this.frameRequest = requestAnimationFrame(this.update)
        this.frame++
      }
    }
    randomChar() {
      return this.chars[Math.floor(Math.random() * this.chars.length)]
    }
  }
  
  const phrases = [
    'CIPP Consulting'
  ]
  
  const el = document.querySelector('.logo')
  const fx = new TextScramble(el)
  
  let counter = 0
  const next = () => {
    fx.setText(phrases[counter]).then(() => {
      setTimeout(next, 30000)
    })
    counter = (counter + 1) % phrases.length
  }
  
  next()


/* TEXT ROTATER */ 

  
var TxtRotate = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 2000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };
  
  TxtRotate.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];
  
    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }
  
    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
  
    var that = this;
    var delta = 300 - Math.random() * 100;
  
    if (this.isDeleting) { delta /= 2; }
  
    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === '') {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }
  
    setTimeout(function() {
      that.tick();
    }, delta);
  };
  
  window.onload = function() {
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i=0; i<elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-rotate');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // INJECT CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };



  
  var wrapperMenu = document.querySelector('.wrapper-menu');
  var sidebar = document.querySelector('.sidebar');
  var mobileMenu = document.querySelector('.mobile-nav');


  mobileMenu.addEventListener('click', function(){
      wrapperMenu.classList.toggle('open');  
      sidebar.classList.toggle('open');  
  })

 $('.menuItem a').click(function(e){
       wrapperMenu.classList.toggle('open');  
      sidebar.classList.toggle('open');  
 })



  $(document).ready(function() {

      var colors = [
          '#4c08c9', 
          '#9d3ed8', 
          '#6f1ba2', 
          '#223e98', 
          '#3e8ad8',  
          '#0a52a5', 
          '#0eb646', 
          '#248842', 
          '#14b39d', 
          '#ff6347', 
          '#ff7600', 
          '#b70047',
          '#fa8072',
          '#708090'
          ];

      var hexagons = document.querySelectorAll(".hexagon");

      for (var i = 0, len = hexagons.length; i < len; i++ ) {
        
         hexagons[i].style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
      }

      
      var generatedColor = colors[Math.floor(Math.random() * colors.length)];
      
      hexagons[0].style.backgroundColor =  generatedColor

      var h1s = document.querySelectorAll("h1");
      h1s[0].style.color = generatedColor 
      
  
      var h2s = document.querySelectorAll("h2");

      for (var i = 0, len = h2s.length; i < len; i++ ) {

          h2s[i].style.color = generatedColor

      }

      var highlights = document.querySelectorAll(".highlight");

      for (var i = 0, len = highlights.length; i < len; i++ ) {

          highlights[i].style.color = generatedColor 

      }


      var sidebar = document.querySelectorAll(".sidebar");

      sidebar[0].style.borderColor = generatedColor


      
      var wrapper = document.querySelectorAll(".content-wrapper");

      wrapper[0].style.borderColor = generatedColor


      var cubesides = document.querySelectorAll(".cubesides");

      for (var i = 0, len = cubesides.length; i < len; i++ ) {

          cubesides[i].style.backgroundColor  =  generatedColor;

      }           

       
      var buttons = document.querySelectorAll(".btn");

      for (var i = 0, len = buttons.length; i < len; i++ ) {

          buttons[i].style.backgroundColor  =  generatedColor;

      } 

      var inputs = document.querySelectorAll(".form-control");

      for (var i = 0, len = inputs.length; i < len; i++ ) {

          inputs[i].style.borderColor  =  generatedColor;
          inputs[i].style.boxShadow =  "6px 6px 0px"+generatedColor;

      } 
         
      var menuitems = document.querySelectorAll(".menuItem");
      var classer = colors.indexOf(generatedColor);

      for (var i = 0, len = menuitems.length; i < len; i++ ) {

        menuitems[i].classList.add("color-"+classer); 

      } 

      var hamburgers = document.querySelectorAll(".line-menu");

      for (var i = 0, len = hamburgers.length; i < len; i++ ) {

        hamburgers[i].style.backgroundColor  = colors[Math.floor(Math.random() * colors.length)];

      }    

    window.addEventListener("mousedown", (e) => {
      const color = colors.shift();
      document.documentElement.style.setProperty("--highlight-color", color);
      colors.push(color);
      });
  
  
  
          
  });
      

  var colors = [
          '#4c08c9', 
          '#9d3ed8', 
          '#6f1ba2', 
          '#223e98', 
          '#3e8ad8',  
          '#0a52a5', 
          '#3cb371', 
          '#248842', 
          '#14b39d', 
          '#ff6347', 
          '#ff7600', 
          '#b70047',
          '#fa8072',
          '#708090'
          ];

      var hexagons = document.querySelectorAll(".hexagon");

  function myFunction(){
           for (var i = 1, len = hexagons.length; i < len; i++ ) {
           hexagons[i].style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
           }
       }

   setInterval(function(){
           myFunction()
   }, 5000)



   $('#reload').click(function () {
      $.ajax({
          type: 'GET',
          url: 'reload-captcha',
          success: function (data) {
              $(".captcha span").html(data.captcha);
          }
      });

      
});
   
